<template>
  <div class="documentLogs">
    <div class="documentLogs__title border-bottom d-flex align-items-center">
      <div class="pr-1">
        <i class="el-icon-date fs-4"></i>
      </div>
      <div>
        <h4 class="mb-0 text-dark">Document Logs</h4>
        <p class="logs-text text-muted">
          All Recipients activities on this document
        </p>
      </div>
    </div>
    <div class="documentLogs_body">
      <el-scrollbar>
        <div
          class="logs"
          v-for="(log, index) in documentLogs"
          :key="index"
          @click="viewDetailedLog(log)"
        >
          <div class="logs-avatar">
            <el-tag class="avatar-text color-6" v-if="log.user_id">
              {{ log.user_id | getUserNameAvatar }}
            </el-tag>
            <el-tag class="avatar-text color-6" v-if="log.contact_id">
              {{ log.contact_id | getUserNameAvatar }}
            </el-tag>
          </div>
          <div class="logs-content">
            <h5 v-if="log.user_id">{{ log.user_id | getUserFullName }}</h5>
            <h5 v-if="log.contact_id">
              {{ log.contact_id | getUserFullName }}
            </h5>
            <p class="logs-date">
              <i class="el-icon-time"></i>
              <span class="date_time">
                {{ log.created_at | globalDateTimeFormat }}
              </span>
            </p>

            <el-tag
              class="recipient-status capitalize"
              :type="log.status | getLogStatusType"
              >{{ log.status | parseText }}</el-tag
            >
          </div>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog
      title="Detailed log"
      :visible.sync="detailedDialogVisible"
      width="30%"
      style="margin-top: 50px"
    >
      <div v-if="correntLog && document">
        <!-- <div class="dialog-head">
        <h2>{{document.name}}</h2>
        <span class="float-right">Created at: {{document.created_at | globalDateTimeFormat}}</span>
        </div> -->
        <h4>From : {{ document.user_id | getUserFullName }}</h4>
        <h6 v-if="document.user_id && document.user_id.email">
          Email: {{ document.user_id.email }}
        </h6>
        <h5 v-if="correntLog.user_id">
          To: {{ correntLog.user_id | getUserFullName }}
        </h5>
        <h5 v-if="correntLog.contact_id">
          To: {{ correntLog.contact_id | getUserFullName }}
        </h5>
        <h6 v-if="correntLog.contact_id && correntLog.contact_id.email">
          Email: {{ correntLog.contact_id.email }}
        </h6>
        <h6 v-if="correntLog.user_id && correntLog.user_id.email">
          Email: {{ correntLog.user_id.email }}
        </h6>
        <h6 v-if="correntLog.ip_address">
          IP address: {{ correntLog.ip_address }}
        </h6>
        <div class="status">
          <h6 v-if="correntLog.description">
            {{ correntLog.description }} at
            {{ correntLog.created_at | globalDateTimeFormat }}
          </h6>
          <!-- <h6 class="float-right">At: </h6> -->
        </div>

        <div class="status" v-if="correntLog.newexpirationdate">
          <h6>
            OLD EXPIRATION DATE :
            {{ correntLog.oldexpirationdate | globalDateFormat }}
          </h6>
          <h6>
            New EXPIRATION DATE :
            {{ correntLog.newexpirationdate | globalDateFormat }}
          </h6>
        </div>
        <div class="status" v-if="correntLog.securechange">
          <h6>Changes Secure Status To: {{ correntLog.securechange }}</h6>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmpDoc-DocumentLogs",
  props: ["document", "logs"],
  data() {
    return {
      documentLogsEntry: {
        logsTxt: "",
      },
      documentLogs: [],
      detailedDialogVisible: false,
      correntLog: {},
    };
  },
  computed: {
    ...mapGetters("documents", ["getDocumentLogs"]),
  },
  async mounted() {
    // this.documentLogs = this.logs
    await this.$store.dispatch("documents/fetchDocumentLogsById", {
      document_id: this.$route.params.employee_document_id,
    });
    if (this.getDocumentLogs && this.getDocumentLogs.data) {
      this.documentLogs = this.getDocumentLogs.data;
    }
  },
  methods: {
    viewDetailedLog(log) {
      this.detailedDialogVisible = true;
      this.correntLog = log;
    },
    handleCommand(command) {
      this.$message("click on item " + command);
    },
  },
  filters: {
    parseText(value) {
      return value.replace(/_/g, " ").toLowerCase();
    },
    getLogStatusType(status) {
      if (status === "DOCUMENT_SENT") {
        return "";
      } else if (
        status === "SIGNATURE_COMPLETED" ||
        status === "DOCUMENT_FINISHED"
      ) {
        return "success";
      } else if (
        status === "DOCUMENT_APPROVED" ||
        status === "DOCUMENT_SETTINGS_CHANGED"
      ) {
        return "warning";
      } else if (
        status === "DOCUMENT_REJECTED" ||
        status === "DOCUMENT_DECLINED"
      ) {
        return "danger";
      } else {
        return "info";
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("documents/setDocumentLogs",null,{root:true})
  }
};
</script>

<style lang="scss">
.documentLogs {
  .documentLogs__title {
    padding: 10px 15px;
  }
  .documentLogs_body {
    // max-height: calc(100vh - 319px) !important;
    overflow-y: auto;
    margin-bottom: 2em !important;
    .logs {
      cursor: pointer;
      display: grid;
      grid-template-columns: 45px 1fr;
      padding: 12px 10px 12px;
      border-top: 1px solid #f9f9f9;
      &:last-child {
        padding-bottom: 12px;
      }
      .logs-avatar {
        .avatar-text {
          height: 35px;
          width: 35px;
          font-weight: 600;
          font-size: 1.1em;
          line-height: 35px;
          text-align: center;
          border-radius: 30px;
          padding: 0;
          background-color: #ffffff;
        }
      }
      .logs-content {
        position: relative;
        h5 {
          font-size: 1.05em;
          color: #444444;
          font-weight: 600;
          line-height: 18px;
          margin-top: 0.15em;
          margin-bottom: 0.25em;
        }
        .logs-date {
          color: #5b627c;
          font-size: 0.9em;
          margin-bottom: 0.25em;
          .date_time {
            margin-left: 3px;
          }
        }
        .recipient-status {
          padding: 3px 8px;
          line-height: 1;
          font-size: 0.85em;
          font-weight: 500;
          height: auto;
          letter-spacing: 0.02em;
        }
      }
      .color-1 {
        border: 1.5px solid #f2994a;
        color: #f2994a;
      }
      .color-2 {
        border: 1.5px solid #2d9cdb;
        color: #2d9cdb;
      }

      .color-3 {
        border: 1.5px solid #bb6bd9;
        color: #bb6bd9;
      }
      .color-4 {
        border: 1.5px solid #27ae60;
        color: #27ae60;
      }
      .color-5 {
        border: 1.5px solid #1f22ec;
        color: #1f22ec;
      }
      .color-6 {
        border: 1.5px solid #1d6648;
        color: #1d6648;
      }
      .color-7 {
        border: 1.5px solid #e20215;
        color: #e20215;
      }
      .color-8 {
        border: 1.5px solid #ff008c;
        color: #ff008c;
      }
    }
    .el-scrollbar {
      overflow-y: auto;
      scrollbar-width: none;
      .el-scrollbar__wrap {
        margin-bottom: 0px !important;
      }
    }
  }
}
</style>
<style lang="scss">
.is-mobile {
  .documentLogs {
    .documentLogs_body {
      .el-scrollbar__wrap {
        overflow-y: auto;
        max-height: calc(100vh - 242px) !important;
      }
    }
  }
}
.documentLogs {
  .el-dropdown-menu {
    padding: 0;
    &:hover,
    &:focus {
      outline: none;
    }
  }
  .el-dropdown-menu__item {
    padding: 0 15px;
    line-height: 30px;
  }
  .documentLogs_body {
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .el-scrollbar__wrap {
      overflow-y: auto;
      max-height: calc(100vh - 274px);
    }
  }
  .documentLogs_entry {
    position: relative;
    height: 100px;
    .inline-entry-field {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      margin: 2px 2px 0;
    }
    .sendNote__Btn {
      position: absolute;
      bottom: 0;
      right: 5px;
      z-index: 100;
    }
  }
}
.status {
  align-items: grid;
}
</style>
